import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import classes from './Progress.module.scss';

const Progress = ({ current, total }) => {
    if (current === -1 && total === -1) {
        return <>Not available</>;
    }

    const percent = current / total * 100;

    let statusClass;
    if (percent < 80) {
        statusClass = 'is-success';
    }
    else if (percent < 90) {
        statusClass = 'is-warning';
    }
    else {
        statusClass = 'is-danger';
    }

    const label = <>
        <NumberFormat
            value={current}
            displayType={'text'}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
        />
        /
        <NumberFormat
            value={total}
            displayType={'text'}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
        />
    </>;

    return (
        <div className={classes.Progress}>
            <progress className={clsx('progress is-medium', statusClass)} value={current} max={total} />
            <div className={classes.Label}>{label}</div>
        </div>
    );
};

Progress.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number.isRequired
};

export default Progress;