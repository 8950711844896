import axios from 'axios';
import store from '../store/store';

const url = process.env.REACT_APP_API_URL;

const instance = {
    client: axios.create({}),
    apiPath: url
};

instance.client.interceptors.request.use(async config => {
    config.headers['X-Api-Key'] = store.getState().auth.user?.token;

    config.headers.authorization = `Bearer ${process.env.REACT_APP_API_TOKEN}`;

    return config;
}, error => {
    return Promise.reject(error);
});

export default instance;

export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;
