/*eslint default-case: 0*/
import produce from 'immer';
import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

export const partnerReducer = (state = initialState.partner, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.LOAD_PARTNERS_SUCCESS:
                draft.data = action.payload;
                return;
        }
    });
