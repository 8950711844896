import clsx from 'clsx';
import { find, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../../../shared/axiosConfig';
import AutomatedTime from '../../AutomatedTime/AutomatedTime';
import Icon from '../../Icon/Icon';
import Modal from '../../Modal/Modal';
import Spinner from '../../Spinner/Spinner';
import classes from './AutomatedTimeDetail.module.scss';

const AutomatedTimeDetail = props => {
    const { onClose, username } = props;
    const [ detail, setDetail ] = useState([]);

    const [ refreshing, setRefreshing ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (!!username) {
            setLoading(true);

            axios.client.get(`${axios.apiPath}/automatedTime?username=${encodeURIComponent(username)}`)
                .then(response => {
                    setDetail(response.data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [ username ]);

    const handleRefresh = (month, year) => {
        let loading = find(refreshing, { year, month });
        if (!loading) {
            setRefreshing(refreshing => [ { month, year }, ...refreshing ]);

            axios.client.get(`${axios.apiPath}/automatedTime/refresh?username=${encodeURIComponent(username)}&month=${month}&year=${year}`)
                .then(response => {
                    console.log('Got', response.data);
                    setDetail(detail => {
                        const i = findIndex(detail, { year, month });

                        const updatedDetail = [ ...detail ];
                        if (i >= 0) {
                            // Replace
                            updatedDetail[ i ] = response.data;
                        }
                        else {
                            // Append
                            updatedDetail.push(response.data);
                        }

                        return updatedDetail;
                    });
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    setRefreshing(refreshing => {
                        return refreshing.filter(item => !(item.month === month && item.year === year));
                    });
                });
        }
    };

    const getDetail = (period, year) => {
        let time = 0;

        if (typeof period == 'number') {
            const detailObject = find(detail, { year, month: period });
            if (!!detailObject) {
                time = detailObject.time;
            }
        }
        else {
            let obj1, obj2, obj3;

            if (period === 'q1') {
                obj1 = find(detail, { year, month: 1 }) || { time: 0 };
                obj2 = find(detail, { year, month: 2 }) || { time: 0 };
                obj3 = find(detail, { year, month: 3 }) || { time: 0 };
            }
            else if (period === 'q2') {
                obj1 = find(detail, { year, month: 4 }) || { time: 0 };
                obj2 = find(detail, { year, month: 5 }) || { time: 0 };
                obj3 = find(detail, { year, month: 6 }) || { time: 0 };
            }
            else if (period === 'q3') {
                obj1 = find(detail, { year, month: 7 }) || { time: 0 };
                obj2 = find(detail, { year, month: 8 }) || { time: 0 };
                obj3 = find(detail, { year, month: 9 }) || { time: 0 };
            }
            else if (period === 'q4') {
                obj1 = find(detail, { year, month: 10 }) || { time: 0 };
                obj2 = find(detail, { year, month: 11 }) || { time: 0 };
                obj3 = find(detail, { year, month: 12 }) || { time: 0 };
            }

            time = obj1.time + obj2.time + obj3.time;
        }

        let icon = 'redo';
        let spin = false;
        let loading = find(refreshing, { year, month: period });

        if (loading) {
            icon = 'spinner';
            spin = true;
        }

        return <div
            className={clsx(classes.Detail, loading && classes.Loading, typeof period === 'number' && classes.Clickable)}>
            <AutomatedTime automatedTime={time} />
            {typeof period === 'number' && <div className={classes.Icon} onClick={() => handleRefresh(period, year)}>
                <Icon name={icon} spin={spin} />
            </div>}
        </div>;
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i);
    const tableContent = years.map((year, index) => {
        return <Fragment key={`row-time-${index}`}>
            <tr>
                <td colSpan={12} className="has-text-centered">{year}</td>
            </tr>
            <tr>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
                    <td className="has-text-centered" key={month}
                        style={{ borderRight: `${(index + 1) % 3 === 0 && index !== 11 ? '1px solid black' : '0'}` }}>
                        {new Date(year, month - 1, 1).toLocaleString('en', { month: 'short' })}
                    </td>
                ))}
            </tr>
            <tr>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
                    <td key={month}
                        style={{ borderRight: `${(index + 1) % 3 === 0 && index !== 11 ? '1px solid black' : '0'}` }}>{getDetail(month, year)}</td>
                ))}
            </tr>
            <tr>
                <td colSpan="3" className="has-text-centered" style={{ borderRight: '1px solid black' }}>Q1</td>
                <td colSpan="3" className="has-text-centered" style={{ borderRight: '1px solid black' }}>Q2</td>
                <td colSpan="3" className="has-text-centered" style={{ borderRight: '1px solid black' }}>Q3</td>
                <td colSpan="3" className="has-text-centered">Q4</td>
            </tr>
            <tr>
                <td colSpan="3" style={{ borderRight: '1px solid black' }}>{getDetail('q1', year)}</td>
                <td colSpan="3" style={{ borderRight: '1px solid black' }}>{getDetail('q2', year)}</td>
                <td colSpan="3" style={{ borderRight: '1px solid black' }}>{getDetail('q3', year)}</td>
                <td colSpan="3">{getDetail('q4', year)}</td>
            </tr>
        </Fragment>;
    });

    console.log('Rendering detail', detail);

    return <Modal show={!!username} onToggle={onClose} style={{ width: 'auto' }}>
        Automated time detail for {username}
        {loading && <Spinner />}
        {!loading && <table className="table is-striped">
            <tbody>
            {tableContent}
            </tbody>
        </table>}
    </Modal>;
};

AutomatedTimeDetail.propTypes = {
    username: PropTypes.string,
    onClose: PropTypes.func.isRequired
};

export default AutomatedTimeDetail;
