import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { clearCustomers, getOwnCustomers, searchCustomers } from '../../../store/actions/customerActions';
import { getPartners } from '../../../store/actions/partnerActions';

const PartnerAdmin = ({ partnerId, onChange }) => {
    const [ loading, setLoading ] = useState(false);
    const partners = useSelector(state => state.partner.data.entities);
    const dispatch = useDispatch();
    const [ searching, setSearching ] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [ query, setQuery ] = useState('');

    useEffect(() => {
        setLoading(true);
        dispatch(getPartners()).then(() => {
            setLoading(false);
        });
    }, [ dispatch ]);

    const search = location.search;

    const doSearch = query => {
        setSearching(true);

        dispatch(searchCustomers(query)).then(() => {
            setSearching(false);
        }).catch(err => {
            toast.error(err.message);
        }).finally(() => {
            setSearching(false);
        });
    };

    useEffect(() => {
        console.log('Search routed to', search);

        let searchParams = new URLSearchParams(search);
        const query = searchParams.get('search');

        if (!!query) {
            console.log('Searching on load', query);
            doSearch(query);
            setQuery(query);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = e => {
        e.preventDefault();
        doSearch(query);
    };

    const handleLoadInternalOrders = () => {
        setSearching(true);
        dispatch(getOwnCustomers())
            .catch(err => {
                toast.error(err.message);
                dispatch(clearCustomers());
            })
            .finally(() => {
                setSearching(false);
            });
    };

    useEffect(() => {
        history.push({
            search: '?search=' + query
        });
    }, [ query, history ]);

    const handleChange = e => {
        console.log('Setting partner to', e.target.value);
        onChange(e.target.value);
        setQuery('');
    };

    // Group partners by email domain
    const partnersGrouped = {};
    Object.values(partners).forEach(partner => {
        const { email } = partner;
        const domain = email.substring(email.indexOf('@') + 1);
        const group = partnersGrouped[ domain ] || [];
        group.push(partner);

        partnersGrouped[ domain ] = group;
    });

    const options = [];
    Object.keys(partnersGrouped).sort().forEach(groupKey => {
        const optGroup = <optgroup label={groupKey} key={groupKey}>
            {partnersGrouped[ groupKey ].map(partner => <option key={partner.id}
                                                                value={partner.id}>{partner.email} (erpId: {partner.erp_id})</option>)}
        </optgroup>;

        options.push(optGroup);
    });

    const partnerErpId = partnerId ? Object.values(partners).find(p => p.id === partnerId).erp_id : -1;

    return <div className="box" style={{ display: 'flex' }}>
        <div style={{ maxWidth: '600px', width: '600px', marginBottom: '20px' }}>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label" style={{ width: '200px', textAlign: 'left' }}>Choose a partner</label>
                </div>
                {loading && <BeatLoader
                    color={'#2d7cc0'}
                    size={10}
                />}
                {!loading && <div className="control" style={{ display: 'flex' }}>
                    <div className="select is-small">
                        <select onChange={handleChange} value={partnerId}>
                            <option value="">--</option>
                            {options}
                        </select>
                    </div>
                </div>}
            </div>
            <form onSubmit={handleSearch}>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label" style={{ width: '200px', textAlign: 'left' }}>...or search for AIDA
                                                                                               user</label>
                    </div>
                    <p className="control has-icons-left has-icons-right">
                        <input
                            className="input"
                            type="search"
                            placeholder="user@aidacloud.com AIDA user"
                            size="160"
                            onChange={e => {
                                onChange('');
                                setQuery(e.target.value);
                            }}
                            value={query}
                            name="query"
                        />
                        <span className="icon is-small is-left">
                      <i className="fas fa-search" />
                    </span>
                    </p>
                    <button className="button is-primary" type="submit" disabled={searching}>
                        {searching ? 'Searching...' : 'Search'}
                    </button>
                </div>
            </form>
            <button className="button is-primary" onClick={handleLoadInternalOrders} disabled={searching}>
                {searching ? 'Searching...' : 'Show internal orders'}
            </button>
        </div>
        {partnerId &&
            <a style={{ marginLeft: '20px' }} target="_blank" rel="noopener noreferrer"
               href={`https://erp.tclab.it/societe/soc.php?socid=${partnerErpId}`}>Partner on ERP <i
                className="fas fa-external-link" /></a>}
    </div>;
};

PartnerAdmin.propTypes = {
    partnerId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default PartnerAdmin;
