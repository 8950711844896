import { normalize } from 'normalizr';
import axios from '../../shared/axiosConfig';
import * as actionTypes from './actionTypes';
import * as schema from './schema';

export const clearCustomers = () => {
    return {
        type: actionTypes.LOAD_CUSTOMERS_SUCCESS,
        payload: {
            entities: {},
            result: []
        }
    };
};

export const getCustomers = partnerId => {
    return dispatch => {
        const partnerIdParameter = partnerId ? `partnerId=${encodeURIComponent(partnerId)}` : '';

        return axios.client.get(`${axios.apiPath}/customer?${partnerIdParameter}`).then(response => {
            const data = normalize(response.data, schema.arrayOfCustomers);
            dispatch({
                type: actionTypes.LOAD_CUSTOMERS_SUCCESS,
                payload: {
                    entities: data.entities.customers,
                    result: data.result
                }
            });
        });
    };
};

export const getOwnCustomers = () => {
    return dispatch => {
        return axios.client.get(`${axios.apiPath}/customer`).then(response => {
            const data = normalize(response.data, schema.arrayOfCustomers);
            dispatch({
                type: actionTypes.LOAD_CUSTOMERS_SUCCESS,
                payload: {
                    entities: data.entities.customers,
                    result: data.result
                }
            });
        });
    };
};

export const searchCustomers = query => {
    return dispatch => {
        return axios.client.get(`${axios.apiPath}/customer?search=${query}`).then(response => {
            const data = normalize(response.data, schema.arrayOfCustomers);
            dispatch({
                type: actionTypes.LOAD_CUSTOMERS_SUCCESS,
                payload: {
                    entities: data.entities.customers,
                    result: data.result
                }
            });
        });
    };
};

