import PropTypes from 'prop-types';
import React from 'react';
import classes from './AutomatedTime.module.scss';

export function formatAutomatedTime(elapsed) {
    let content;

    if (elapsed === 0) {
        content = 0;
    }
    else {
        elapsed *= 1000;
        const msPerMinute = 60_000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 8;
        const msPerWeek = msPerHour * 40;
        const msPerMonth = msPerHour * 176;

        if (elapsed < msPerHour) {
            content = Math.round(elapsed / msPerMinute) + 'm';
        }
        else if (elapsed < msPerDay) {
            const hours = Math.floor(elapsed / msPerHour);
            const minutes = Math.round((elapsed - (hours * msPerHour)) / msPerMinute);

            content = <>{hours}<span className="color-gray4">h&nbsp;</span>{minutes}<span
                className="color-gray4">m&nbsp;</span></>;
        }
        else if (elapsed < msPerWeek) {
            // Days
            const days = Math.floor(elapsed / msPerDay);
            const hours = Math.floor((elapsed - (days * msPerDay)) / msPerHour);
            const minutes = Math.round((elapsed - (days * msPerDay) - (hours * msPerHour)) / msPerMinute);

            content = <>{days}<span className="color-gray4">d&nbsp;</span>{hours}<span
                className="color-gray4">h&nbsp;</span>{minutes}<span className="color-gray4">m&nbsp;</span></>;
        }
        else if (elapsed < msPerMonth) {
            // Weeks
            const weeks = Math.floor(elapsed / msPerWeek);
            const days = Math.floor((elapsed - (weeks * msPerWeek)) / msPerDay);
            const hours = Math.floor((elapsed - (weeks * msPerWeek) - (days * msPerDay)) / msPerHour);

            content = <>{weeks}<span className="color-gray4">w&nbsp;</span>{days}<span
                className="color-gray4">d&nbsp;</span>{hours}<span className="color-gray4">h&nbsp;</span></>;
        }
        else {
            // Months
            const months = Math.floor(elapsed / msPerMonth);
            const weeks = Math.floor((elapsed - (months * msPerMonth)) / msPerWeek);
            const days = Math.floor((elapsed - (months * msPerMonth) - (weeks * msPerWeek)) / msPerDay);

            content = <>{months}<span className="color-gray4">M&nbsp;</span>{weeks}<span
                className="color-gray4">w&nbsp;</span>{days}<span className="color-gray4">d&nbsp;</span></>;
        }
    }

    return <span className="is-family-monospace">{content}</span>;
}

const AutomatedTime = ({ automatedTime }) => {
    return (
        <div className={classes.AutomatedTime}>
            {formatAutomatedTime(automatedTime)}
        </div>
    );
};

AutomatedTime.propTypes = {
    automatedTime: PropTypes.number.isRequired
};

export default AutomatedTime;
