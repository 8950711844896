import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Auth0Provider} from './contexts/auth0-context';
import './styles/variables.scss';
import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import {Provider} from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import './sentry';
import {Helmet} from 'react-helmet';
import {getPortalInfo} from './shared/utils';
import Favicon from 'react-favicon';
import store from './store/store';

const noop = () => {
};

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_CI_JOB_NAME.indexOf('dev') === -1) {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
}

const { documentTitle } = getPortalInfo();

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            <title>{documentTitle}</title>
            <meta content={documentTitle} name="description"/>
        </Helmet>
        <Favicon url={getPortalInfo().favicon}/>
        <Auth0Provider>
            <ErrorBoundary>
                <Provider store={store}>
                    <App/>
                </Provider>
            </ErrorBoundary>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
