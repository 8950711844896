import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem } from '../../../store/actions/orderActions';

export default function AdditionalItemsForm() {
    const dispatch = useDispatch();

    const order = useSelector(state => state.order.data);
    const { additionalItems, deployment } = order;

    const getQuantity = (id) => additionalItems[ id ] || 0;

    const getRow = (id, description, maxQuantity = -1) => {
        const quantity = getQuantity(id);
        const handleIncrement = () => dispatch(addItem(id));
        const handleDecrement = () => dispatch(removeItem(id));

        return <tr>
            <td>{description}</td>
            <td>
                <div className="field has-addons">
                    <div className="control">
                        <button onClick={handleDecrement} className="button is-primary"
                                disabled={quantity === 0}>
                    <span className="icon">
                        <i className="fas fa-minus"/>
                    </span>
                        </button>
                    </div>

                    <div className="control">
                        <input
                            className="input"
                            value={quantity}
                            readOnly={true}
                            size={3}
                            style={{ textAlign: 'center', minWidth: '3em' }}
                        />
                    </div>
                    <div className="control">
                        <button onClick={handleIncrement} className="button is-primary"
                                disabled={maxQuantity > -1 ? quantity >= maxQuantity : false}>
                    <span className="icon">
                        <i className="fas fa-plus"/>
                    </span>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    };

    return (
        <>
            <h3>Additional options and services</h3>
            <table className="table is-striped">
                <thead>
                <tr>
                    <th style={{ width: '100%' }}>Name</th>
                    <th>Quantity</th>
                </tr>
                </thead>
                <tbody>
                {getRow('AIDA-service', 'Professional service day')}
                {getRow('AIDA-onboarding', 'Onboarding day')}
                {deployment === 'hybrid' && getRow('DS-installation', 'DocuShare installation (requires remote access)', 1)}
                </tbody>
            </table>
        </>
    )
}
