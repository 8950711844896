import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Modal extends Component {
    ref = React.createRef();

    shouldComponentUpdate( nextProps ) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    }

    handleClose = () => {
        this.props.onToggle();
    };

    render() {
        const { show, children, style = {} } = this.props;

        return ReactDOM.createPortal(
            <div className={clsx('modal', show && 'is-active')} ref={this.ref}>
                <div className="modal-background" onClick={this.handleClose}/>
                <div className="modal-content" style={style}>
                    <div className="box">
                        {children}
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={this.handleClose}/>
            </div>,
            document.body
        );
    }
}

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    style: PropTypes.object,
    onToggle: PropTypes.func.isRequired
};

export default Modal;
