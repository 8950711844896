import React from 'react';
import {BounceLoader} from 'react-spinners';
import classes from './Spinner.module.scss';

const Spinner = () => {
    return (
        <div className={classes.Spinner}>
            <BounceLoader
                color={'#2d7cc0'}
            />
        </div>
    );
};

export default Spinner;