import React from 'react';
import classes from './Renewals.module.scss';

const Renewals = () => {
    return (
        <div className={classes.Renewals}>
            <h1>Contract renewals and upgrades</h1>
            <p>Here are the available options and the related legal terms.</p>
            <table>
                <thead>
                <tr>
                    <th>Time remaining to the end of the cycle</th>
                    <th>Type</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>More than a month</td>
                    <td>Standard upgrade to a higher plan</td>
                    <td>Upgrade to a higher plan with renewed expiration and consumed pages resetting to zero.</td>
                </tr>
                <tr>
                    <td>More than a month</td>
                    <td>Upgrade to a higher plan with page discount</td>
                    <td>Upgrade to a plan with more pages, applying a discount equal to the current plan, while keeping
                        the original contract's expiration dates. Consumed pages remain unchanged.
                    </td>
                </tr>
                <tr>
                    <td>Less than a month</td>
                    <td>Anticipation of contract renewal</td>
                    <td>The plan is renewed in advance, while maintaining the original renewal cycle.</td>
                </tr>
                <tr>
                    <td>Any</td>
                    <td>One-time purchase of pages</td>
                    <td>Extraordinary purchase of a lower plan to be used in case of page exhaustion before the natural
                        expiration, without altering the existing contract dates. Any applied discounts are halved.
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Renewals;
