export const AIDA_PRODUCT = 'aida';
export const AIDA_DS_PRODUCT = 'aidaDs';

const initialProduct = AIDA_PRODUCT;

const orderCountry = window.localStorage.getItem('order.country') || '';
const orderLanguage = window.localStorage.getItem('order.language') || '';

const initialState = {
    order: {
        data: {
            product: initialProduct,
            deployment: null,
            baseProduct: initialProduct === AIDA_DS_PRODUCT ? '' : 'aida',
            duration: -1,
            users: -1,
            pages: '',
            additionalItems: {}
        },
        username: '',
        company: '',
        vat: '',
        cap: '',
        city: '',
        address: '',
        orderRef: '',
        orderNote: '',
        orderType: '',
        priceOneOff: -1,
        priceYear: -1,
        price: -1,
        calculating: false,
        firstName: '',
        lastName: '',
        country: orderCountry,
        language: orderLanguage
    },
    ui: {
        currency: window.localStorage.getItem('currency') || 'EUR',
        appLoaded: false
    },
    auth: {
        user: null
    },
    customer: {
        data: {
            entities: {},
            result: []
        }
    },
    partner: {
        data: {
            entities: {},
            result: []
        }
    }
};

export default initialState;
