import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classes from './Listing.module.scss';

const Listing = () => {
    const currency = useSelector(state => state.ui.currency);
    let { product } = useParams();

    let title;
    switch (product) {
        case 'aida':
            title = `AIDA Enterprise ${currency} listing`;
            break;
        case 'aida-ds-cloud':
            title = `AIDA/DocuShare bundle ${currency} listing (cloud deployment)`;
            break;
        case 'aida-ds-hybrid':
            title = `AIDA/DocuShare bundle ${currency} listing (hybrid deployment)`;
            break;
        default:
            title = null;
    }

    return (
        <div className={classes.Listing}>
            <h1>{title}</h1>
            {currency === 'EUR' && product === 'aida' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTsqeMUNxVs4eesPoEZLJWFdPC_wtKHedbnv_JuPm2qhHlunoCcNLWRZhywLADSipaV9cuOjfVeUE-J/pubhtml?gid=811159046&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'GBP' && product === 'aida' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTsqeMUNxVs4eesPoEZLJWFdPC_wtKHedbnv_JuPm2qhHlunoCcNLWRZhywLADSipaV9cuOjfVeUE-J/pubhtml?gid=1531062848&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'USD' && product === 'aida' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTsqeMUNxVs4eesPoEZLJWFdPC_wtKHedbnv_JuPm2qhHlunoCcNLWRZhywLADSipaV9cuOjfVeUE-J/pubhtml?gid=1756628593&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'EUR' && product === 'aida-ds-cloud' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvxmo__LJd1DEDCzmTM0ZUU4mwiwBpxfCYrXEbmc7iYoVwHN-JiJZw9IjvUY6rYe3B--cw1-6ysTHB/pubhtml?gid=1175064814&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'GBP' && product === 'aida-ds-cloud' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvxmo__LJd1DEDCzmTM0ZUU4mwiwBpxfCYrXEbmc7iYoVwHN-JiJZw9IjvUY6rYe3B--cw1-6ysTHB/pubhtml?gid=849458609&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'USD' && product === 'aida-ds-cloud' &&
            <div className="notification is-warning"><p>Sorry, but this product isn't yet available in this currency
                                                        area.</p><p>Please select either Euro or Pound sterling as your
                                                                    current currency with the menu to the
                                                                    top right of your screen.</p></div>}
            {currency === 'EUR' && product === 'aida-ds-hybrid' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvxmo__LJd1DEDCzmTM0ZUU4mwiwBpxfCYrXEbmc7iYoVwHN-JiJZw9IjvUY6rYe3B--cw1-6ysTHB/pubhtml?gid=517704703&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'GBP' && product === 'aida-ds-hybrid' && <iframe
                title={title}
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSvxmo__LJd1DEDCzmTM0ZUU4mwiwBpxfCYrXEbmc7iYoVwHN-JiJZw9IjvUY6rYe3B--cw1-6ysTHB/pubhtml?gid=376378744&amp;single=true&amp;widget=true&amp;headers=false"
            />}
            {currency === 'USD' && product === 'aida-ds-hybrid' &&
            <div className="notification is-warning"><p>Sorry, but this product isn't yet available in this currency
                                                        area.</p><p>Please select either Euro or Pound sterling as your
                                                                    current currency with the menu to the
                                                                    top right of your screen.</p></div>}
        </div>
    );
};

export default Listing;