import React from 'react';
import { Link } from 'react-router-dom';

export default function OrderSuccess() {
    return (
        <div className="container">
            <h5>Thank you!</h5>
            <p>Your order has been sent to Technology & Cognition LAB for final approval, you will hear back from us as
               soon as the
               order will be processed.</p>
            <p>If you wish to contact us, please do so at <a
                href="mailto:support@aidacloud.com">support@aidacloud.com</a>.</p>
            <Link to="/order" className="button is-primary">
                <span className="icon">
                        <i className="fas fa-arrow-left" />
                    </span>
                <span>Go back to orders generation</span>
            </Link>
        </div>
    );
}
