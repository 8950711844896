import PropTypes from 'prop-types';
import React from 'react';
import classes from './Products.module.scss';

const Products = ({ products }) => {
    const content = products.map(p => {
        let descriptionString = p.description;
        const m = p.description.match(/(.+)(\s-\scliente finale\s?.+)/i);
        if (m && m.length === 3) {
            descriptionString = m[ 1 ];
        }

        return <div key={`product-${p.id}`}>{descriptionString}</div>;
    });

    return (
        <div className={classes.Products}>
            {content}
        </div>
    );
};

Products.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Products;