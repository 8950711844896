import React from 'react';
import classes from './Promotions.module.scss';

const Promotions = () => {
    return (
        <div className={classes.Promotions}>
            <h1>Promotions</h1>
            <iframe
                src="https://docs.google.com/presentation/d/e/2PACX-1vRqvj2k6t-zbG_eSy_p79Tmg0FNp_Zm_cY3gAmtW0yAgN76ln_5knMMPf05Wu-FXA/embed?start=false&loop=false&delayms=10000"
                frameBorder="0" width="1000px" height="600px" allowFullScreen="true" mozallowfullscreen="true"
                webkitallowfullscreen="true" title="AIDA Promotions"></iframe>
        </div>
    );
};

export default Promotions;
