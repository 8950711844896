import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './reducers/authReducer';
import { customerReducer } from './reducers/customerReducer';
import { orderReducer } from './reducers/orderReducer';
import { partnerReducer } from './reducers/partnerReducer';
import { uiReducer } from './reducers/uiReducer';

const rootReducer = combineReducers({
    order: orderReducer,
    ui: uiReducer,
    auth: authReducer,
    customer: customerReducer,
    partner: partnerReducer
});

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
