import React, {useEffect, useState} from 'react';
import {useAuth0} from '../../contexts/auth0-context';
import {getUserDetails} from '../../shared/utils';

import classes from './PartnerBadge.module.scss';
import clsx from 'clsx';
import {startCase} from 'lodash';

export default ( { showIcon = true } ) => {
    const { user } = useAuth0();
    const [ level, setLevel ] = useState(null);

    useEffect(() => {
        const level = user ? getUserDetails(user).level : null;
        setLevel(level);
    }, [ user ]);

    let levelBadge;
    if (level) {
        const imgSrc = require(`../../assets/images/partner-badge/partner-badge-${level.toLowerCase()}-small.png`);
        levelBadge = <img src={imgSrc} alt={`Partner level ${level} icon`}/>
    }
    else {
        levelBadge = 'Loading...';
    }

    return <div className={clsx(classes.PartnerBadge, level && classes[startCase(level.toLowerCase())])}>
        {level}{showIcon && levelBadge}
    </div>;
};
