import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import { useAuth0 } from '../../../contexts/auth0-context';
import { getCountries, getLanguages, getOrderTypes, getPortalInfo, getUserDetails } from '../../../shared/utils';
import {
    calculatePricing,
    generateOrder,
    setAddress,
    setCap,
    setCity,
    setCompany,
    setCountry,
    setFirstName,
    setLanguage,
    setLastName,
    setOrderAction,
    setOrderNote,
    setOrderRef,
    setOrderType,
    setPricing,
    setUsername,
    setVat
} from '../../../store/actions/orderActions';
import initialState from '../../../store/reducers/initialState';
import Confirm from '../../Confirm/Confirm';
import PartnerBadge from '../../PartnerBadge/PartnerBadge';
import FormattedPrice from './FormattedPrice';
import classes from './Summary.module.scss';

const Summary = () => {
    const [ isGenerating, setGenerating ] = useState(false);
    const order = useSelector(state => state.order.data);

    const username = useSelector(state => state.order.username);
    const company = useSelector(state => state.order.company);
    const city = useSelector(state => state.order.city);
    const cap = useSelector(state => state.order.cap);
    const address = useSelector(state => state.order.address);
    const vat = useSelector(state => state.order.vat);
    const firstName = useSelector(state => state.order.firstName);
    const lastName = useSelector(state => state.order.lastName);
    const language = useSelector(state => state.order.language);
    const country = useSelector(state => state.order.country);
    const orderRef = useSelector(state => state.order.orderRef);
    const orderNote = useSelector(state => state.order.orderNote);
    const orderType = useSelector(state => state.order.orderType);

    const isCalculating = useSelector(state => state.order.calculating);
    const priceOneOff = useSelector(state => state.order.priceOneOff);
    const priceYear = useSelector(state => state.order.priceYear);
    const price = useSelector(state => state.order.price);
    const currency = useSelector(state => state.ui.currency);
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const history = useHistory();
    const [ usernameError, setUsernameError ] = useState('');
    const isBase = getUserDetails(user).level === 'BASE';

    const [ thirdparty, setThirdparty ] = useImmer({
        name: '',
        vat: '',
        country: ''
    });

    const requestThirdpartyInfo = typeof user[ 'https://partners.xhecom.com/app_metadata' ].erp_id === 'undefined';

    useEffect(() => {
        if (username.toLowerCase() === user.email.toLowerCase()) {
            setUsernameError('Insert customer\'s email, not yours');
        }
        else {
            setUsernameError('');
        }
    }, [ username, user.email ]);

    const handleSetThirdparty = e => {
        const { name, value } = e.target;
        setThirdparty(draft => {
            draft[ name ] = value;
        });
    };

    const handleGenerate = () => {
        setGenerating(true);

        try {
            const erpId = user[ 'https://partners.xhecom.com/app_metadata' ].erp_id;
            dispatch(generateOrder(order, currency, user.email, erpId, firstName, lastName, username, company, vat, address, city, cap, country, language, orderRef, orderNote, thirdparty, orderType)).then(() => {
                setGenerating(false);
                history.push('/order/success');
                dispatch(setOrderAction({ ...initialState.order.data }));
                dispatch(setUsername(''));
                dispatch(setPricing({ price: -1, priceYear: -1, priceOneOff: -1 }));
                dispatch(setOrderRef(''));
                dispatch(setCompany(''));
                dispatch(setVat(''));
                dispatch(setFirstName(''));
                dispatch(setLastName(''));
                dispatch(setCap(''));
                dispatch(setCity(''));
                dispatch(setAddress(''));
                dispatch(setOrderNote(''));
                dispatch(setOrderType(''));
            });
        }
        catch (error) {
            window.Sentry.captureException(error);
            setGenerating(false);
            toast.error(`An error happened while generating your order, please try after reloading the page or contact us at ${getPortalInfo().email}`);
        }
    };

    const handleSetUsername = e => {
        const { value } = e.target;
        dispatch(setUsername(value));
    };

    const handleSetCompany = (e) => {
        dispatch(setCompany(e.target.value));
    };

    const handleSetVat = (e) => {
        dispatch(setVat(e.target.value));
    };

    const handleSetFirstName = (e) => {
        dispatch(setFirstName(e.target.value));
    };

    const handleSetLastName = (e) => {
        dispatch(setLastName(e.target.value));
    };

    const handleSetOrderType = (e) => {
        dispatch(setOrderType(e.target.value));
    };

    const handleSetCap = (e) => {
        dispatch(setCap(e.target.value));
    };

    const handleSetAddress = (e) => {
        dispatch(setAddress(e.target.value));
    };

    const handleSetCity = (e) => {
        dispatch(setCity(e.target.value));
    };

    const handleSetLanguage = (e) => {
        dispatch(setLanguage(e.target.value));
    };

    const handleSetCountry = (e) => {
        const country = e.target.value;
        dispatch(setCountry(country));
        switch (country) {
            case 'IT':
                dispatch(setLanguage('IT'));
                break;
            case 'FR':
                dispatch(setLanguage('FR'));
                break;
            case 'ES':
                dispatch(setLanguage('ES'));
                break;
            case 'PT':
                dispatch(setLanguage('PT'));
                break;
            case 'GB':
            case 'US':
                dispatch(setLanguage('EN'));
                break;
            case 'DE':
                dispatch(setLanguage('DE'));
                break;
            default:
                break;
        }
    };

    const handleSetOrderRef = (e) => {
        dispatch(setOrderRef(e.target.value));
    };

    const handleSetOrderNote = (e) => {
        dispatch(setOrderNote(e.target.value));
    };

    useEffect(() => {
        if (!!order.deployment && !!order.product) {
            dispatch(calculatePricing(order, currency, user.email, orderType));
        }
    }, [ order, dispatch, currency, user.email, orderType ]);

    const isValid = (priceOneOff > -1 && priceYear > -1 && price > -1) || isCalculating;

    const usernameIsValid = username.length > 0 && usernameError === '';

    const thirdpartyIsValid = requestThirdpartyInfo ? thirdparty.name.length > 0 && thirdparty.vat.length > 0 && thirdparty.country.length > 0 : true;

    return <div className={clsx('box', classes.Summary)}>
        <h4>Summary</h4>
        {isValid && <ul>
            <li>Price (one-off): <FormattedPrice price={priceOneOff} currency={currency} loading={isCalculating} /></li>
            <li>Price (yearly): <FormattedPrice price={priceYear} currency={currency} loading={isCalculating} /></li>
            <li>
                <hr />
            </li>
            <li className="has-text-weight-bold">Price (total): <FormattedPrice price={price} currency={currency}
                                                                                loading={isCalculating} /></li>
        </ul>}
        {!isValid &&
            <div className="notification is-info is-light">Once you've selected your desired product and options to the
                                                           left, pricing information will appear here.</div>}
        {isValid && <>
            <div className={classes.Notice}>Generated price includes your exclusive <PartnerBadge
                showIcon={false} /> partner level discount
            </div>
            <hr />
            <p style={{ fontSize: '0.9rem' }}>Enter the end customer's details under which you intend to
                                              create the
                                              account. Fields marked with * are required:</p>
            {requestThirdpartyInfo && <>
                <h5>Your information</h5>
                <div className="field">
                    <label className="label">Your company name *</label>
                    <p className="control has-icons-left">
                        <input name="name"
                               className={clsx('input is-small', thirdparty.name.length > 0 && 'is-success')}
                               type="text"
                               placeholder="Acme Inc." value={thirdparty.name}
                               onChange={handleSetThirdparty} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </p>
                </div>
                <div className="field">
                    <label className="label">Your company VAT number *</label>
                    <p className="control has-icons-left">
                        <input name="vat" className={clsx('input is-small', thirdparty.vat.length > 0 && 'is-success')}
                               type="text"
                               value={thirdparty.vat}
                               onChange={handleSetThirdparty} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-id-card" />
                        </span>
                    </p>
                </div>
                <div className="field">
                    <label className="label">Your country *</label>
                    <div className="control has-icons-left">
                        <div
                            className={clsx('select is-small is-block', thirdparty.country.length > 0 && 'is-success')}>
                            <select name="country" value={thirdparty.country} onChange={handleSetThirdparty}>
                                <option value="">---</option>
                                {getCountries().map(o => <option key={`country-${o.value}`}
                                                                 value={o.value}>{o.displayValue}</option>)}
                            </select>
                            <div className="icon is-small is-left">
                                <i className="fas fa-globe" />
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            <h5>Customer/Order information</h5>
            <div className="field">
                <label className="label">Order type *</label>
                <div className="control has-icons-left">
                    <div className={clsx('select is-small is-block', orderType.length > 0 && 'is-success')}>
                        <select value={orderType} onChange={handleSetOrderType}>
                            <option value="">---</option>
                            {getOrderTypes().map(o => <option key={`country-${o.value}`}
                                                              value={o.value}>{o.displayValue}</option>)}
                        </select>
                        <div className="icon is-small is-left">
                            <i className="fas fa-file-contract" />
                        </div>
                    </div>
                </div>
                <a href="/web/renewals" target="_blank" style={{ fontSize: '0.9rem', marginTop: '5px' }}
                   rel="noopener noreferrer"><i
                    className="fas fa-external-link" />&nbsp;Rules for existing customers</a>
            </div>
            <div className="field">
                <label className="label">First name *</label>
                <p className="control has-icons-left">
                    <input className={clsx('input is-small', firstName.length > 0 && 'is-success')} type="text"
                           placeholder="Customer first name" value={firstName}
                           onChange={handleSetFirstName} />
                    <span className="icon is-small is-left">
                            <i className="fas fa-id-card" />
                        </span>
                </p>
            </div>
            <div className="field">
                <label className="label">Last name</label>
                <p className="control has-icons-left">
                    <input className="input is-small" type="text" placeholder="Customer last name" value={lastName}
                           onChange={handleSetLastName} />
                    <span className="icon is-small is-left">
                            <i className="fas fa-id-card" />
                        </span>
                </p>
            </div>
            <div className="field">
                <label className="label">Company *</label>
                <p className="control has-icons-left">
                    <input className={clsx('input is-small', company.length > 0 && 'is-success')} type="text"
                           placeholder="Customer company" value={company}
                           onChange={handleSetCompany} />
                    <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                </p>
            </div>
            {isBase && <>
                <hr />
                <h5>Direct order info</h5>
                <div className="field">
                    <label className="label">VAT Number *</label>
                    <p className="control has-icons-left">
                        <input className={clsx('input is-small', vat.length > 0 && 'is-success')} type="text"
                               placeholder="Customer VAT" value={vat}
                               onChange={handleSetVat} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </p>
                </div>
                <div className="field">
                    <label className="label">Address *</label>
                    <p className="control has-icons-left">
                        <input className={clsx('input is-small', address.length > 0 && 'is-success')} type="text"
                               placeholder="Customer address" value={address}
                               onChange={handleSetAddress} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </p>
                </div>
                <div className="field">
                    <label className="label">City *</label>
                    <p className="control has-icons-left">
                        <input className={clsx('input is-small', city.length > 0 && 'is-success')} type="text"
                               placeholder="Customer city and state/province" value={city}
                               onChange={handleSetCity} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </p>
                </div>
                <div className="field">
                    <label className="label">CAP *</label>
                    <p className="control has-icons-left">
                        <input className={clsx('input is-small', cap.length > 0 && 'is-success')} type="text"
                               placeholder="Customer CAP" value={cap}
                               onChange={handleSetCap} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-building" />
                        </span>
                    </p>
                </div>
                <hr />
            </>}
            <div className="field">
                <label className="label">Customer email *</label>
                <p className="control has-icons-left">
                    <input className={clsx('input is-small', usernameIsValid ? 'is-success' : 'is-error')} type="email"
                           placeholder="Customer email" value={username}
                           onChange={handleSetUsername} />
                    <span className="icon is-small is-left">
                            <i className="fas fa-envelope" />
                        </span>
                </p>
                {usernameError && <p className="help is-danger">{usernameError}</p>}
            </div>
            <div className="field">
                <label className="label">Country *</label>
                <div className="control has-icons-left">
                    <div className={clsx('select is-small is-block', country.length > 0 && 'is-success')}>
                        <select value={country} onChange={handleSetCountry}>
                            <option value="">---</option>
                            {getCountries().map(o => <option key={`country-${o.value}`}
                                                             value={o.value}>{o.displayValue}</option>)}
                        </select>
                        <div className="icon is-small is-left">
                            <i className="fas fa-globe" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Language *</label>
                <div className="control has-icons-left">
                    <div className={clsx('select is-small is-block', language.length > 0 && 'is-success')}>
                        <select value={language} onChange={handleSetLanguage}>
                            <option value="">---</option>
                            {getLanguages().map(o => <option key={`country-${o.value}`}
                                                             value={o.value}>{o.displayValue}</option>)}
                        </select>
                        <div className="icon is-small is-left">
                            <i className="fas fa-language" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Order reference</label>
                <p className="control has-icons-left">
                    <input className="input is-small" type="text" placeholder="Your internal order reference"
                           value={orderRef}
                           onChange={handleSetOrderRef} />
                    <span className="icon is-small is-left">
                            <i className="fas fa-hashtag" />
                        </span>
                </p>
            </div>
            <div className="field">
                <label className="label">Order note</label>
                <p className="control">
                    <input className="input is-small" type="text"
                           placeholder="Anything you need to specify about this order"
                           value={orderNote}
                           onChange={handleSetOrderNote} />
                </p>
            </div>
            <Confirm
                body="Are you sure you want to confirm and send this order?"
                onConfirm={handleGenerate}
            >
                <button
                    style={{ marginTop: '10px' }}
                    className={clsx('button', 'is-primary', isGenerating && 'is-loading')}
                    disabled={isCalculating || username.length === 0 || company.length === 0 || firstName.length === 0 || country.length === 0 || language.length === 0 || username.toLowerCase() === user.email.toLowerCase() || !thirdpartyIsValid || orderType.length === 0}
                >
                    Generate order
                </button>
            </Confirm>
        </>}
    </div>;
};

export default Summary;

