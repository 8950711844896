import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AVAILABLE_CURRENCIES, formatDate, getPortalInfo, isAdmin } from '../../shared/utils';
import { clearCustomers, getCustomers } from '../../store/actions/customerActions';
import AutomatedTime from '../AutomatedTime/AutomatedTime';
import Icon from '../Icon/Icon';
import Progress from '../Progress/Progress';
import Spinner from '../Spinner/Spinner';
import AutomatedTimeDetail from './AutomatedTimeDetails/AutomatedTimeDetail';
import classes from './Customers.module.scss';
import PartnerAdmin from './PartnerAdmin/PartnerAdmin';
import Products from './Products/Products';

const Customers = () => {
    const [ loading, setLoading ] = useState(false);
    const dispatch = useDispatch();
    const customers = useSelector(state => state.customer.data);
    const user = useSelector(state => state.auth.user);
    const [ productFilter, setProductFilter ] = useState('');
    const [ partnerId, setPartnerId ] = useState('');
    const [ automatedTimeDetail, setAutomatedTimeDetail ] = useState(null);

    useEffect(() => {
        console.log('Called useEffect partnerId', partnerId, 'isAdmin', isAdmin(user));
        if (isAdmin(user)) {
            // Load by partner id
            if (partnerId.length > 0) {
                setLoading(true);
                dispatch(getCustomers(partnerId)).catch(err => {
                    toast.error(err.message);
                    dispatch(clearCustomers());
                }).finally(() => {
                    setLoading(false);
                });
            }
            else {
                dispatch(clearCustomers());
            }
        }
        else {
            // Load own customers
            setLoading(true);
            dispatch(getCustomers()).catch(err => {
                toast.error(err.message);
                dispatch(clearCustomers());
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [ dispatch, user, partnerId ]);

    const handleSetProductFilter = e => {
        setProductFilter(e.target.value);
    };

    const handleClickAutomatedTime = userId => {
        setAutomatedTimeDetail(userId);
    };

    const parseDateForExpiration = inputDateTimestamp => {
        const parsedDate = moment.unix(inputDateTimestamp);
        const today = moment().startOf('day');
        return parsedDate.isBefore(today);
    };

    const { email } = getPortalInfo();

    let content = <Spinner />;
    if (!loading) {
        if (customers.result.length === 0) {
            if (isAdmin(user)) {
                content =
                    <div className="notification is-info is-light">
                        <p>No customer found.</p>
                        <p>Please select a partner from the menu above.</p>
                    </div>;
            }
            else {
                content =
                    <div className="notification is-info is-light">
                        <p>There is no customer, yet.</p>
                        <p>You have to start by <Link to="/order">creating an order first.</Link></p>
                        <p>If you did create orders in the past, you have to request activation for this page at <a
                            href="mailto:support@aidacloud.com">support@aidacloud.com</a>; we'll gladly do it for you.
                        </p>
                    </div>;
            }
        }
        else {
            const { thousandSeparator, decimalSeparator, symbol } = AVAILABLE_CURRENCIES[ 'EUR' ];

            content = <>
                <table className="table is-bordered is-striped is-narrow">
                    <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Internal ref</th>
                        <th>Date</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Products</th>
                        <th>Total</th>
                        <th>Customer</th>
                        <th style={{ width: '300px' }}>Progress <a href="#progress">*</a></th>
                        <th>Time saved <a href="#automated">**</a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.result.map((id, i) => {
                        const order = customers.entities[ id ];

                        let matchesFilter = true;
                        if (productFilter.length > 0) {
                            let productMatches = false;
                            order.products.forEach(product => {
                                productMatches = productMatches || product.description.toLowerCase().indexOf(productFilter.toLowerCase()) >= 0;
                            });

                            const refMatches = order.ref.toLowerCase().indexOf(productFilter.toLowerCase()) >= 0;
                            const partnerRefMatches = order.partnerRef.toLowerCase().indexOf(productFilter.toLowerCase()) >= 0;
                            matchesFilter = refMatches || productMatches || partnerRefMatches;
                        }

                        if (!matchesFilter) {
                            return null;
                        }

                        let startDate = 'NA';
                        let endDate = 'NA';
                        if (order.products.length > 0) {
                            startDate = formatDate(new Date(order.products[ 0 ].start));
                            endDate = formatDate(new Date(order.products[ 0 ].end));
                        }

                        const current = order.progress?.current ? parseInt(order.progress.current) : -1;
                        const total = order.progress?.total ? parseInt(order.progress.total) : -1;

                        let customerString = 'NA';
                        if (order.products.length > 0) {
                            const description = order.products[ 0 ].description;
                            const m = description.match(/(.+\s-\scliente finale\s?)(.+)/i);
                            if (m && m.length === 3) {
                                customerString = m[ 2 ];
                            }
                        }

                        const partnerUsername = <div className={classes.Username}>
                            <i className="fas fa-building" />{order.partner || 'NA'}
                        </div>;

                        const customerUsername = <div className={classes.Username}>
                            <i className="fas fa-user" />{order.username || 'NA'}
                        </div>;

                        const isExpired = endDate === 'NA' ? false : parseDateForExpiration(order.products[ 0 ].end);

                        let progressComponent;
                        if (isExpired) {
                            const isStarter = total === 20;
                            if (isStarter) {
                                progressComponent = <>Contract has expired: user is in read-only mode</>;
                            }
                            else {
                                progressComponent = <Progress current={current} total={total} />;
                            }
                        }
                        else {
                            progressComponent = <Progress current={current} total={total} />;
                        }

                        return <tr key={`row-${i}`} className={isExpired ? classes.Expired : ''}>
                            <td>{order.ref}{partnerUsername}</td>
                            <td>{order.partnerRef}</td>
                            <td>{formatDate(order.date)}</td>
                            <td>{startDate}</td>
                            <td>{endDate}</td>
                            <td><Products products={order.products} /></td>
                            <td className="is-family-monospace has-text-right">
                                <NumberFormat
                                    value={order.total.taxable.replace('.', ',')}
                                    displayType={'text'}
                                    prefix={symbol + ' '}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                />
                            </td>
                            <td className={classes.Customer}>
                                {customerString}
                                {customerUsername}
                            </td>
                            <td>
                                {progressComponent}
                            </td>
                            <td onClick={() => handleClickAutomatedTime(order.username)}>
                                <div style={{ display: 'flex' }}>
                                    <AutomatedTime automatedTime={parseInt(order.progress.automatedTime || 0, 10)} />
                                    <>&nbsp;<Icon name="info-circle" /></>
                                </div>
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
                <div className="message is-primary" id="progress">
                    <div className="message-body">
                        <p>* Progress is updated every 15 minutes</p>
                        <p>If you don't see progress data linked to a contract/customer of yours, please contact us
                           at <a
                                href={`mailto:${email}`}>{email}</a>
                        </p>
                    </div>
                </div>
                <div className="message is-primary" id="automated">
                    <div className="message-body">
                        <p>** 1 work day = 8 hours, 1 work week = 40 hours, 1 work month = 176 hours</p>
                    </div>
                </div>
            </>;
        }
    }

    return (
        <div className={classes.Customers}>
            <h1>Customers management</h1>
            {isAdmin(user) && <PartnerAdmin partnerId={partnerId} onChange={setPartnerId} />}
            {(!loading && customers.result.length > 0) && <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input className="input" type="search" value={productFilter} onChange={handleSetProductFilter}
                           placeholder="Ref, internal ref, product or customer" />
                    <span className="icon is-small is-left">
                      <i className="fas fa-search" />
                    </span>
                </p>
            </div>}
            <div style={{ position: 'relative', minHeight: '100px' }}>
                {content}
            </div>
            <AutomatedTimeDetail username={automatedTimeDetail} onClose={() => setAutomatedTimeDetail(null)} />
        </div>
    );
};

export default Customers;
