import React from 'react';
import './Footer.css'
import {getPortalInfo} from '../../shared/utils';

export default function Footer() {
    const version = `Version ${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_CI_PIPELINE_ID}`;

    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p>
                    <strong>Partners Portal</strong> by {getPortalInfo().footerCompanyComponent} - {version}
                </p>
            </div>
        </footer>
    );
}