import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { getPortalInfo } from '../../shared/utils';
import Icon from '../Icon/Icon';
import classes from './Home.module.scss';

const NEWS = [
    {
        date: '2020-03-30',
        content: 'Partners portal launched'
    },
    {
        date: '2020-04-01',
        content: 'Pricing updated for April'
    },
    {
        date: '2020-04-02',
        content: 'Added all presentations for viewing and download'
    },
    {
        date: '2020-06-25',
        content: 'Added AIDA legal presentation'
    },
    {
        date: '2020-08-13',
        content: 'Added AIDA HR presentation'
    },
    {
        date: '2020-09-04',
        content: 'Pricing updated according to September 2020 listings'
    },
    {
        date: '2020-12-02',
        content: 'Added AIDA/Xerox Delivery Services presentation'
    },
    {
        date: '2021-01-07',
        content: 'Pricing updated for January 2021'
    },
    {
        date: '2021-06-11',
        content: 'Promotions section added'
    },
    {
        date: '2021-08-12',
        content: 'Added customers section'
    },
    {
        date: '2021-11-12',
        content: 'End of 2021 promotions added'
    },
    {
        date: '2022-01-18',
        content: 'Pricing updated for January 2022'
    },
    {
        date: '2022-04-28',
        content: 'New partner promotions (Q2 2022) added'
    },
    {
        date: '2022-11-02',
        content: 'New partner promotions (end 2022) added'
    },
    {
        date: '2023-01-13',
        content: <>
            <p>Pricing updated for January 2023</p>
            <h6>Major updates</h6>
            <ul>
                <li>Partner discounts have been increased for all levels, from 10/15/20/25% to 20/30/40/60%</li>
                <li>AIDA entry-level plan price has been decreased by 13.9%</li>
                <li>Price for other plans has been decreased by 13%, on average</li>
                <li>Fax option has been removed</li>
            </ul>
            <p>Each partner will soon receive an e-mail with all the details about the changes.</p>
        </>
    },
    {
        date: '2023-02-15',
        content: 'New partner promotions (Q1 2023) added'
    },
    {
        date: '2023-08-22',
        content: 'New english presentations'
    },
    {
        date: '2023-10-02',
        content: 'New partner promotions (Q4 2023) added'
    },
    {
        date: '2023-11-17',
        content: <>
            For more information on rules concerning contracts renewal and upgrades, <Link to="/renewals">check the
                                                                                                         available
                                                                                                          options</Link> and
            the related legal terms.
        </>
    },
    {
        date: '2023-12-05',
        content: <>
            AIDA brochure (english) has been added: <Link to="/presentation/aida-brochure-en">check it out</Link>
        </>
    },
    {
        date: '2024-02-01',
        content: 'Pricing updated for February 2024'
    }
];

const Home = () => {
    const printNews = ( { date, content }, index ) => {
        return <div className={classes.News} key={`news-${index}`}>
            <div className={classes.Date}>
                {dayjs(date).format('DD MMM YYYY')}
            </div>
            <div className={classes.Content}>{content}</div>
        </div>
    };

    return (
        <div className="container">
            <h1>{getPortalInfo().documentTitle}</h1>

            <div className="columns">
                <div className="column">
                    <Link to="/promotions" className={clsx('card', classes.Promotions)}>
                        <div className={classes.Header}>
                            <p className="card-header-title">
                                <Icon name={[ 'fad', 'badge-dollar' ]} />
                                Promotions
                            </p>
                        </div>
                        <div className={clsx('card-content', classes.Content)}>
                            <div className="content">Promotions for partners</div>
                        </div>
                    </Link>
                </div>
                <div className="column">
                    <Link to="/presentation" className={clsx('card', classes.Presentations)}>
                        <div className={classes.Header}>
                            <p className="card-header-title">
                                <Icon name="presentation" />
                                Presentations
                            </p>
                        </div>
                        <div className={clsx('card-content', classes.Content)}>
                            <div className="content">AIDA and AIDA/DocuShare bundle presentations for viewing and
                                download
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="column">
                    <Link to="/listing" className={clsx('card', classes.Listings)}>
                        <div className={classes.Header}>
                            <p className="card-header-title">
                                <Icon name="list"/>Listings
                            </p>
                        </div>
                        <div className={clsx('card-content', classes.Content)}>
                            <div className="content">AIDA and AIDA/DocuShare bundle full listings with details on
                                available discounts
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="column">
                    <Link to="/order" className={clsx('card', classes.Order)}>
                        <div className={classes.Header}>
                            <p className="card-header-title">
                                <Icon name="file-contract"/>
                                Order form
                            </p>
                        </div>
                        <div className={clsx('card-content', classes.Content)}>
                            <div className="content">AIDA and AIDA/DocuShare bundle order module with total and annual
                                                     TCO summary
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            {/*<h3>Promotions&nbsp;&nbsp;&nbsp;<Icon name="bell-on" color="red" /></h3>
            <article className="message is-primary">
                <div className="message-body">Special promotions for partners for end of 2021 are active, <Link
                    to="/promotions">check them on the dedicated page</Link>.
                </div>
            </article>*/}

            <h3>News</h3>
            {NEWS.sort((n1, n2) => n1.date > n2.date ? -1 : 1).map((news, i) => printNews(news, i))}
        </div>
    );
};

export default Home;
