export const SET_ORDER = 'SET_ORDER';
export const ADD_ADDITIONAL_ITEM = 'ADD_ADDITIONAL_ITEM';
export const REMOVE_ADDITIONAL_ITEM = 'REMOVE_ADDITIONAL_ITEM';
export const SET_ORDER_CALCULATING = 'SET_ORDER_CALCULATING';
export const SET_ORDER_USERNAME = 'SET_ORDER_USERNAME';
export const SET_ORDER_ORDERREF = 'SET_ORDER_ORDERREF';
export const SET_ORDER_ORDERNOTE = 'SET_ORDER_ORDERNOTE';
export const SET_ORDER_COMPANY = 'SET_ORDER_COMPANY';
export const SET_ORDER_VAT = 'SET_ORDER_VAT';
export const SET_ORDER_FIRST_NAME = 'SET_ORDER_FIRST_NAME';
export const SET_ORDER_ORDER_TYPE = 'SET_ORDER_ORDER_TYPE';
export const SET_ORDER_LAST_NAME = 'SET_ORDER_LAST_NAME';
export const SET_ORDER_CITY = 'SET_ORDER_CITY';
export const SET_ORDER_CAP = 'SET_ORDER_CAP';
export const SET_ORDER_ADDRESS = 'SET_ORDER_ADDRESS';
export const SET_ORDER_LANGUAGE = 'SET_ORDER_LANGUAGE';
export const SET_ORDER_COUNTRY = 'SET_ORDER_COUNTRY';
export const SET_ORDER_PRICING = 'SET_ORDER_PRICING';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_USER = 'SET_USER';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS';
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS';
