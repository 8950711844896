import { normalize } from 'normalizr';
import axios from '../../shared/axiosConfig';
import * as actionTypes from './actionTypes';
import * as schema from './schema';

export const getPartners = () => {
    return dispatch => {
        return axios.client.get(`${axios.apiPath}/partner`).then(response => {
            const data = normalize(response.data, schema.arrayOfPartners);
            dispatch({
                type: actionTypes.LOAD_PARTNERS_SUCCESS,
                payload: {
                    entities: data.entities.partners,
                    result: data.result
                }
            });
        });
    };
};

