/*eslint default-case: 0*/
import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';
import initialState from './initialState';

export const authReducer = ( state = initialState.auth, action ) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_USER:
                draft.user = action.payload;
                return;
        }
    });
