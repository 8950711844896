/*eslint default-case: 0*/
import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';
import initialState from './initialState';

export const uiReducer = ( state = initialState.ui, action ) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_CURRENCY:
                draft.currency = action.payload;
                return;
            case actionTypes.SET_APP_LOADED:
                draft.appLoaded = action.payload;
                return;
        }
    });
