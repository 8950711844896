import * as actionTypes from './actionTypes';

export const setCurrency = ( currencyId ) => {
    return {
        type: actionTypes.SET_CURRENCY,
        payload: currencyId
    }
};

export const setAppLoaded = ( appLoaded ) => {
    return {
        type: actionTypes.SET_APP_LOADED,
        payload: appLoaded
    }
};
