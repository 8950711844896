import React from 'react';
import clsx from 'clsx';
import AIDADSForm from './AIDADSForm/AIDADSForm';
import Summary from './Summary/Summary';
import {useDispatch, useSelector} from 'react-redux';
import {setOrderAction} from '../../store/actions/orderActions';
import initialState, {AIDA_DS_PRODUCT, AIDA_PRODUCT} from '../../store/reducers/initialState';
import AIDAForm from './AIDAForm/AIDAForm';

export default function OrderForm() {
    const order = useSelector(state => state.order.data);

    const dispatch = useDispatch();

    const handleSetProduct = ( product ) => {
        dispatch(setOrderAction({
            ...initialState.order.data,
            product
        }));
    };

    const getProductButton = ( id, label, disabled = false ) => {
        return <button
            className={clsx('button', order.product === id ? 'is-primary is-selected' : null)}
            onClick={() => handleSetProduct(id)}
            disabled={disabled}
        >{label}</button>
    };

    return (
        <div className="container">
            <h1>Partner order form</h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box">
                        <div className="field">
                            <label className="label">Product family</label>
                            <div className="buttons has-addons">
                                {getProductButton(AIDA_PRODUCT, 'AIDA')}
                                {getProductButton(AIDA_DS_PRODUCT, 'AIDA/DocuShare bundle')}
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        {order.product === AIDA_PRODUCT && <AIDAForm/>}
                        {order.product === AIDA_DS_PRODUCT && <AIDADSForm/>}
                    </div>
                </div>
                <div className="column is-4">
                    <Summary/>
                </div>
            </div>
        </div>
    )
}