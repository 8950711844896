/*eslint default-case: 0*/
import produce from 'immer';
import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

export const orderReducer = (state = initialState.order, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_ORDER:
                draft.data = action.payload;
                return;
            case actionTypes.SET_ORDER_CALCULATING:
                draft.calculating = action.payload;
                return;
            case actionTypes.SET_ORDER_USERNAME:
                draft.username = action.payload;
                return;
            case actionTypes.SET_ORDER_ORDERREF:
                draft.orderRef = action.payload;
                return;
            case actionTypes.SET_ORDER_ORDERNOTE:
                draft.orderNote = action.payload;
                return;
            case actionTypes.SET_ORDER_COMPANY:
                draft.company = action.payload;
                return;
            case actionTypes.SET_ORDER_VAT:
                draft.vat = action.payload;
                return;
            case actionTypes.SET_ORDER_CITY:
                draft.city = action.payload;
                return;
            case actionTypes.SET_ORDER_ADDRESS:
                draft.address = action.payload;
                return;
            case actionTypes.SET_ORDER_CAP:
                draft.cap = action.payload;
                return;
            case actionTypes.SET_ORDER_FIRST_NAME:
                draft.firstName = action.payload;
                return;
            case actionTypes.SET_ORDER_ORDER_TYPE:
                draft.orderType = action.payload;
                return;
            case actionTypes.SET_ORDER_LAST_NAME:
                draft.lastName = action.payload;
                return;
            case actionTypes.SET_ORDER_COUNTRY:
                draft.country = action.payload;
                return;
            case actionTypes.SET_ORDER_LANGUAGE:
                draft.language = action.payload;
                return;
            case actionTypes.SET_ORDER_PRICING:
                draft.priceOneOff = action.payload.priceOneOff;
                draft.priceYear = action.payload.priceYear;
                draft.price = action.payload.price;
                return;
            case actionTypes.ADD_ADDITIONAL_ITEM: {
                const id = action.payload;
                draft.data.additionalItems[ id ] = state.data.additionalItems[ id ] ? state.data.additionalItems[ id ] + 1 : 1;
                return;
            }
            case actionTypes.REMOVE_ADDITIONAL_ITEM: {
                const id = action.payload;
                draft.data.additionalItems[ id ] = state.data.additionalItems[ id ] ? state.data.additionalItems[ id ] - 1 : 0;
                return;
            }
        }
    });
