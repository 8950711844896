import { throttle } from 'lodash';
import axios, { CancelToken, isCancel } from '../../shared/axiosConfig';
import * as actionTypes from './actionTypes';

let cancel;

export const setOrderAction = (order) => {
    return {
        type: actionTypes.SET_ORDER,
        payload: order
    };
};

export const setOrderCalculating = (calculating) => {
    return {
        type: actionTypes.SET_ORDER_CALCULATING,
        payload: calculating
    };
};

export const setPricing = (prices) => {
    return {
        type: actionTypes.SET_ORDER_PRICING,
        payload: prices
    };
};

export const setUsername = payload => {
    return {
        type: actionTypes.SET_ORDER_USERNAME,
        payload
    };
};

export const setOrderRef = payload => {
    return {
        type: actionTypes.SET_ORDER_ORDERREF,
        payload
    };
};

export const setOrderNote = payload => {
    return {
        type: actionTypes.SET_ORDER_ORDERNOTE,
        payload
    };
};

export const setCompany = payload => {
    return {
        type: actionTypes.SET_ORDER_COMPANY,
        payload
    };
};

export const setVat = payload => {
    return {
        type: actionTypes.SET_ORDER_VAT,
        payload
    };
};

export const setFirstName = payload => {
    return {
        type: actionTypes.SET_ORDER_FIRST_NAME,
        payload
    };
};

export const setOrderType = payload => {
    return {
        type: actionTypes.SET_ORDER_ORDER_TYPE,
        payload
    };
};

export const setLastName = payload => {
    return {
        type: actionTypes.SET_ORDER_LAST_NAME,
        payload
    };
};

export const setCity = payload => {
    return {
        type: actionTypes.SET_ORDER_CITY,
        payload
    };
};

export const setCap = payload => {
    return {
        type: actionTypes.SET_ORDER_CAP,
        payload
    };
};

export const setAddress = payload => {
    return {
        type: actionTypes.SET_ORDER_ADDRESS,
        payload
    };
};

export const setLanguage = payload => {
    window.localStorage.setItem('order.language', payload);
    return {
        type: actionTypes.SET_ORDER_LANGUAGE,
        payload
    };
};

export const setCountry = payload => {
    window.localStorage.setItem('order.country', payload);
    return {
        type: actionTypes.SET_ORDER_COUNTRY,
        payload
    };
};

const calculatePricingThrottled = throttle((order, currency, username, orderType, dispatch) => {
    axios.client.post(`${axios.apiPath}/order/calculate`, {
        order,
        currency,
        username,
        orderType
    }, {
        cancelToken: new CancelToken(function executor(c) {
            cancel = c;
        })
    })
        .catch(error => {
            if (!isCancel(error)) {
                window.Sentry.captureException(error);
            }
        })
        .then(response => {
            if (response) {
                console.log('Got calculatePricing response', response);
                dispatch(setOrderCalculating(false));
                dispatch(setPricing(response.data));
            }
            else {
                // Request was probably canceled
            }
        });
}, 500);

export const calculatePricing = (order, currency, username, orderType) => {
    return dispatch => {
        dispatch(setOrderCalculating(true));

        if (cancel) {
            cancel();
        }
        return calculatePricingThrottled(order, currency, username, orderType, dispatch);
    };
};

export const generateOrder = (order, currency, username, erpId, firstName, lastName, email, company, vat, address, city, cap, country, language, orderRef, orderNote, thirdparty, orderType) => {
    return dispatch => {
        const requestFax = false;
        return axios.client.post(`${axios.apiPath}/order/generate`, {
            order,
            currency,
            firstName,
            lastName,
            username,
            erpId,
            email,
            company,
            vat,
            address,
            city,
            cap,
            country,
            language,
            orderRef,
            orderNote,
            requestFax,
            thirdparty,
            orderType
        });
    };
};

export const addItem = (id) => {
    return {
        type: actionTypes.ADD_ADDITIONAL_ITEM,
        payload: id
    };
};

export const removeItem = (id) => {
    return {
        type: actionTypes.REMOVE_ADDITIONAL_ITEM,
        payload: id
    };
};
