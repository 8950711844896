import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPricing } from '../../../store/actions/orderActions';
import initialState from '../../../store/reducers/initialState';

export default function AIDADSForm() {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setPricing({
                priceOneOff: initialState.order.priceOneOff,
                priceYear: initialState.order.priceYear,
                price: initialState.order.price,
            }));
        };
    });

    return (
        <>
            <h3>AIDA/DocuShare bundle order</h3>
            <div className="notification is-warning"><p>To order this product please <a
                href="mailto:onboarding@tclab.it?subject=AIDA/DocuShare bundle order">email us directly</a>.</p></div>
        </>
    )
}
