import React, {Component} from 'react';

import classes from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
    constructor( props ) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError( error ) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch( error, errorInfo ) {
        console.log(error, errorInfo);
        if (process.env.NODE_ENV === 'production') {
            window.Sentry.withScope(scope => {
                scope.setExtras(errorInfo);
                const eventId = window.Sentry.captureException(error);
                this.setState({ eventId });
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return <div className={classes.ErrorBoundary}>
                <h1>Something went wrong, please reload the page and try again.</h1>
                <p>Error id was <pre>{this.state.eventId}</pre></p>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;