import React from 'react';
import { PDFObject } from 'react-pdfobject';
import { useParams } from 'react-router-dom';
import classes from './PresentationPdf.module.scss';

export default function PresentationPdf() {
    let { product } = useParams();

    let url;
    let title;
    switch (product) {
        case 'aida-it':
            title = 'Presentazione AIDA';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-it-v6.pdf';
            break;
        case 'aida-en':
            title = 'AIDA presentation';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-en-v7.pdf';
            break;
        case 'aida-en-partners':
            title = 'AIDA presentation (for Partners)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-en-partners-v7.pdf';
            break;
        case 'aida-es':
            title = 'AIDA presentación';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-es-v6.pdf';
            break;
        case 'aida-ds-bundle-it':
            title = 'AIDA/DocuShare bundle (ITA)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-ds-bundle-it.pdf';
            break;
        case 'aida-ds-bundle-en':
            title = 'AIDA/DocuShare bundle (ENG)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-ds-bundle-en.pdf';
            break;
        case 'aida-legal-it':
            title = 'AIDA/DocuShare per studio legale';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-legal-it.pdf';
            break;
        case 'aida-legal-en':
            title = 'AIDA/DocuShare for legal firms';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-legal-en.pdf';
            break;
        case 'aida-hr-it':
            title = 'AIDA/DocuShare per la gestione delle risorse umane';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-hr-it.pdf';
            break;
        case 'aida-hr-en':
            title = 'AIDA/DocuShare for Human Resources management';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-hr-en.pdf';
            break;
        case 'aida-xerox-delivery-services-it':
            title = 'AIDA/Xerox Delivery Services';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-xerox-delivery-services-it.pdf';
            break;
        case 'aida-xerox-delivery-services-en':
            title = 'AIDA/Xerox Delivery Services';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-xerox-delivery-services-en.pdf';
            break;
        case 'aida-security-en':
            title = 'AIDA Security and certifications';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-security-en.pdf';
            break;
        case 'aida-security-it':
            title = 'AIDA Sicurezza e certificazioni';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-security-it.pdf';
            break;
        case 'aida-brochure-en':
            title = 'AIDA brochure (EN)';
            url = 'https://aida-pub.s3.eu-central-1.amazonaws.com/doc/aida-brochure-en.pdf';
            break;
        default:
            url = null;
    }

    return url ? <div className={classes.PresentationPdf}>
        <h1>{title}</h1>
        <PDFObject url={url}/>
    </div> : <p>Nothing here...</p>
};
