import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { BeatLoader } from 'react-spinners';
import { AVAILABLE_CURRENCIES } from '../../../shared/utils';

const FormattedPrice = ({ price, currency, loading }) => {
    const { thousandSeparator, decimalSeparator, symbol } = AVAILABLE_CURRENCIES[ currency ];

    if (loading) {
        return <span className="is-pulled-right">
              <BeatLoader
                  color={'#2d7cc0'}
                  size={10}
              />
        </span>
    }
    else {
        return price > -1 ?
            <span className="is-pulled-right has-text-weight-bold is-family-monospace">
            <NumberFormat
                value={price}
                displayType={'text'}
                prefix={symbol + ' '}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
            />
        </span> :
            <span className="is-pulled-right is-italic">NA</span>
    }
};

FormattedPrice.defaultProps = {
    loading: false,
    currency: 'EUR'
};

FormattedPrice.propTypes = {
    price: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    currency: PropTypes.oneOf([ 'USD', 'GBP', 'EUR' ]).isRequired
};

export default FormattedPrice;