import React, {Component} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

class Confirm extends Component {
    mounted = true;

    state = {
        show: false
    };

    onButtonClick = () => {
        this.setState({
            show: true
        });
    };

    onClose = ( event ) => {
        if (event) {
            event.stopPropagation();
        }

        this.setState({ show: false });

        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    };

    onConfirm = ( event ) => {
        event.stopPropagation();

        const promise = this.props.onConfirm();
        if (promise) {
            promise.then(() => {
                if (this.mounted) {
                    this.setState({ show: false });
                }
            });
        }
        else {
            this.setState({ show: false });
        }
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { body } = this.props;

        const btn = React.Children.only(this.props.children);
        const content = React.cloneElement(
            btn,
            {
                onClick: this.onButtonClick,
                style: this.props.style
            },
            btn.props.children
        );

        const modal = <div className={clsx('modal', this.state.show && 'is-active')}>
            <div className="modal-background" onClick={this.onClose}/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p>Confirmation request</p>
                </header>
                <section className="modal-card-body">
                    {body}
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-success" onClick={this.onConfirm}>Confirm</button>
                    <button className="button" onClick={this.onClose}>Cancel</button>
                </footer>
            </div>
        </div>;

        return <>
            {content}
            {modal}
        </>
    }
}

Confirm.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    body: PropTypes.oneOfType([ PropTypes.string, PropTypes.object, PropTypes.array ]).isRequired
};

export default Confirm;