import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({ name, color, spin = false }) => {
    const iconClass = Array.isArray(name) ? 'fad' : 'fas';
    const iconName = Array.isArray(name) ? name[ 1 ] : name;

    return <span className="icon" style={{ paddingRight: '0.5em', color }}>
        <i className={clsx(iconClass, `fa-${iconName}`, spin && 'fa-spin')} />
    </span>;
};

Icon.defaultProps = {
    color: 'inherit'
};

Icon.propTypes = {
    name: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]).isRequired,
    color: PropTypes.string.isRequired,
    spin: PropTypes.bool
};

export default Icon;
