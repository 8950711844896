// Mock Sentry in the event that loading fails
window.Sentry = window.Sentry || {
    captureException: () => {
    },
    configureScope: () => {
    },
    init: () => {
    }
};

if (process.env.REACT_APP_SENTRY_DSN) {
    window.Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_CI_PIPELINE_ID}`,
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            'Can\'t find variable: ZiteReader',
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // Added by us
            // eslint-disable-next-line
            'null is not an object \(evaluating \'[A-Za-z]\.style\'\)',
            'Cannot read property \'style\' of null',
            'recaptcha__',
            'timeout of 0ms exceeded'
        ],
        blacklistUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ]
    });

    window.Sentry.configureScope(( scope ) => {
        scope.setUser({
            'username': 'guest'
        });
    });
}
